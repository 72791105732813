import React from 'react';
import Slideshow from '../../components/Slideshow';
import { generateImageList, shuffleList } from '../../utils';

// Change the path here to select a different image folder location for the slideshow
const imageList = generateImageList(require.context('../../images/donations', false));

export default function Donate() {
    shuffleList(imageList);
    return (
        <>
            <div className='page-content remove-bottom-margin'>
                <h1>Our Mission</h1>
                <p>
                    The Band of Iron Lions operates as a registered 501(c)3 Nonprofit Organization. Our core focus
                    lies in the realm of Historical European Martial Arts (H.E.M.A), where we diligently train our
                    students and practitioners using a blend of practical methods, including shadow training, drills,
                    and sparring with both synthetic and steel swords. Beyond honing our craft, we showcase our
                    skills within our local community and at events such as Renaissance Faires. We take great pride
                    in imparting our techniques to guests and patrons, alongside sharing the rich historical context
                    of our time period and martial arts practices. Moreover, our members actively engage in tournaments,
                    where they showcase their prowess, forge connections within the H.E.M.A. community, and uphold the
                    spirit of camaraderie amongst skilled fighters.

                    Donations play a vital role in acquiring essential group equipment such as safety gear, training
                    space, and covering transportation costs for equipment to and from event locations. Beyond
                    financial contributions, members generously volunteer their personal equipment and time,
                    demonstrating their individual commitment to The Band of Iron Lions mission.
                </p>
            </div>
            <Slideshow imageset={imageList}/>
            <div className='page-content'>
                <p>
                    As a 501(c)(3) nonprofit organization, your donations are instrumental in supporting our mission
                    to advance and enhance our programs in Historical European Martial Arts and historic traditions
                    effectively.

                    The Band of Iron Lions genuinely appreciates your support in helping us cover these additional
                    costs, allowing us to continue our work with dedication and commitment.
                </p>
            </div>
        </>
    );
}
