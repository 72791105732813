import React from 'react';
import '../scss/slideshow.scss';

const delay = 6000;

const determineTransform = (index) => {
    if (window.screen.width > 1000) {
        return `translate3d(${-index * 720}px, 0, 0)`;
    } else {
        return `translate3d(${-index * 360}px, 0, 0)`;
    }
};

export default function Slideshow({imageset}) {
    /* keep track of slide */
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    /* this is the 'effect' of moving the slides' */
    React.useEffect(() => {
        /* reset the timer when transition happens, otherwise weirdness occurs...*/
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === imageset.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index]);

    return (
        <div className="slideshow">
            <div className="slideshowSlider"
            /* this is what 'moves' the slides */
                style={{ transform: determineTransform(index) }}
            >
                {imageset.map((image, index) => (
                    <div className="slide" key={index}>
                        <img src={image} className="slideImage"/>
                    </div>
                ))}
            </div>

        </div>
    );
}
