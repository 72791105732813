import React from 'react';
import logo from '../logo.png';
import '../scss/header.scss';
import NavButtons from './NavButtons';

function Header() {
    return (
        <div className='header'>
            <a className='logo' href='/'>
                <img src={logo} alt='Band of Iron Lions'/>
                Band of Iron Lions
            </a>
            <NavButtons tagType={'span'} extraCssClasses={'header-buttons'}/>
        </div>
    );
}

export default Header;
