import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Homepage from './pages';
import Donate from './pages/donate';
import SwordSchool from './pages/swordSchool';
import NotFound from './pages/NotFound';
import Events from './pages/events';
import Footer from './components/Footer';

function renderHeader() {
    const location = window.location.pathname;
    if (location !== '/') {
        return <Header />;
    }
    return null;
}

function App() {
    return (
        <>
            {renderHeader()}
            <BrowserRouter>
                <Routes>
                    <Route index element={<Homepage />} />
                    <Route path='/donate' element={<Donate />} />
                    <Route exact path='/events' element={<Events />} />
                    <Route path='/sword-school' element={<SwordSchool />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </BrowserRouter>
            <Footer />
        </>
    );
}

export default App;
