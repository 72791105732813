import React from 'react';
import '../scss/notfound.scss';

function NotFound() {
    return (
        <div className="content">
            <h1>Page Not Found</h1>
            <p>Whatever it is you are looking for, it is not here.</p>
        </div>
    );
}

export default NotFound;