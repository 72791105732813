import React from 'react';
// import logo from '../logo.png';
import '../scss/footer.scss';
import NavButtons from './NavButtons';

function Footer() {
    const date = new Date().getFullYear();

    // <img className='logo' src={logo} alt='Band of Iron Lions'/>
    return (
        <div className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className='column'>
                        <p className='heading'>Contact Us</p>
                        <p className='text'>
                            Band of Iron Lions<br/>
                            P.O. Box 13063<br/>
                            Des Moines, IA 50310
                        </p>
                        <a
                            href='mailto:contact@bandofironlions.org'
                            target='blank'
                            className='link'
                        >contact@bandofironlions.org</a>
                    </div>
                    <div className='column'>
                        <div className='padding'/> 
                        <NavButtons tagType={'span'} extraCssClasses={'nav-links'}/>
                    </div>
                    <div className='column'>
                        <div className='padding'/> 
                        <a
                            href='https://www.facebook.com/BandOfIronLions/'
                            target='blank'
                            className='link'
                        >Facebook</a>
                        <a
                            href='https://www.instagram.com/bandofironlions/'
                            target='blank'
                            className='link'
                        >Instagram</a>
                    </div>
                </div>
            </div>
            <p className='copyright'>&copy; {date} Band of Iron Lions &bull; webmaster@bandofironlions.org</p>
        </div>
    );
}

export default Footer;
