export function generateImageList(images) {
    return images.keys().map(image => images(image));
}

export function shuffleList(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}
