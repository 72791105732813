import React from 'react';
import Slideshow from '../components/Slideshow';
import '../scss/homepage.scss';
import NavButtons from '../components/NavButtons';
import { generateImageList, shuffleList } from '../utils';

// Change the path here to select a different image folder location for the slideshow
const imageList = generateImageList(require.context('../images/homepage', false));

export default function Homepage() {
    shuffleList(imageList);
    return (
        <div className='homepage-content'>
            <div className='title'>
                The Band of Iron Lions
            </div>
            <NavButtons tagType={'div'} extraCssClasses={'site-nav-buttons'}/>
            <Slideshow imageset={imageList}/>
            <div className='about'>
                Band of Iron Lions operates as a registered 501(c)3 Nonprofit Organization. 
                Our core focus lies in the realm of Historical European Martial Arts (H.E.M.A), where we diligently train our students and 
                practitioners using a blend of practical methods, including shadow training, drills, and sparring with both synthetic and 
                steel swords. Beyond honing our craft, we showcase our skills within our local community and at events such as Renaissance 
                Faires. We take great pride in imparting our techniques to guests and patrons, alongside sharing the rich historical context 
                of our time period and martial arts practices. Moreover, our members actively engage in tournaments, where they showcase their 
                prowess, forge connections within the H.E.M.A. community, and uphold the spirit of camaraderie amongst skilled fighters.   
            </div>
        </div>
    );
}
